.fb {
  width: 100%;
  height: 100%;

  &-menu {
    width: 100%;
    position: absolute;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 60px;
    display: flex;
    z-index: 8;

    &-buttonDiv {
      display: flex;
      align-items: center;
      gap: 15px;
      &-button {
        padding: 12px 20px 12px 20px;
        background-color: #efefef;
        border: none;
        border-radius: 0px;
        color: #1B2540;
        font-size: 12px;
      }
      &-button:hover {
        background-color: #efefef;
        color: #1B2540;
      }
    }

    &-text {
      padding-top: 20px;
      cursor: pointer;
      color: #fff;
      font-family: 'Cormorant Garamond';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 90%;
      letter-spacing: -0.6px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-welcome {
    width: 100%;
    position: relative;
    justify-content: center;
    &-gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 6;
      background: linear-gradient(180deg, rgba(0, 3, 23, 0.3) 0%, rgba(0, 3, 23, 0) 100%);
    }

    &-buttonDiv {
      background-color: #1B2540;
      padding: 24px 0;
      &-button {
        width: 360px;
        z-index: 20;
        border: 0;
        display: flex;
        padding: 18px 32px;
        margin: 0px auto;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background: #efefef;
        color: #1B2540;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        transition: background-color 0.3s ease;
      }
    }

    &-button:hover {
      background-color: #1B2540;
      cursor: pointer;
    }

    &-carusel {
      width: 100%;

      &-textbox {
        z-index: 7;
        width: 100%;
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 40px;

        h1 {
          width: 100%;
          color: #fff;
          text-align: center;
          font-family: 'Cormorant Garamond';
          font-size: 64px;
          font-style: normal;
          font-weight: 400;
          line-height: 57.6px;
          letter-spacing: -3.2px;
          text-transform: uppercase;
          margin-bottom: 24px;
        }

        p {
          color: #fff;
          text-align: center;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.32px;
          text-transform: uppercase;
        }
      }

      .carousel-indicators {
        z-index: 20;
        margin-bottom: 100px;
        padding-bottom: 5px;

        button {
          height: 2px;
          width: 40px;
        }
      }

      &-item {
        height: 900px;

        &-background {
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: 0 0;
        }
      }
    }
  }

  &-discover {
    width: 100%;
    padding-top: 100px;
    display: flex;
    background: #f7f4f0;
    padding-bottom: 32px;
    flex-direction: column;
    align-items: center;

    &__inner {
      width: 582px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 72px;

      &-stars {
        margin-bottom: 24px;
      }

      p {
        color: #78572c;
        text-align: center;
        font-family: Cormorant Garamond;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 90%;
        letter-spacing: -1.08px;
      }
    }

    &__room {
      background: url('../../../assets/images/room.png') no-repeat center;
      width: 100%;
      height: 800px;
    }
  }

  &-startTour {
    padding-top: 60px;
    background-color: #f7f4f0;
    &-title {
      width:100%;
      font-family: Cormorant Garamond;
      color: #1B2540;
      font-size: 64px;
      text-align: center;
      font-weight: 400;
      line-height: 57.6px;
      letter-spacing: -3.2px;
    }
    &-items {
      width: 100%;
      padding: 50px 80px 0 80px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      &-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .ph-page-date-picker-wrapper {
          width: 100%;
          margin-bottom: 50px;
          .jso-booking-form-title {
            display: none;
          }
          .jso-booking-check-in-out-content {
            height: 100%;
            .jso-date-range-picker-text {
              display: block;
              border-color: rgba(27, 37, 64, 0.2) !important;
              padding: 20px 24px;
              color: #072437;
              font-size: 20px;
              font-family: 'Montserrat';
              font-weight: 500;
              line-height: 20px;
              cursor: pointer;

              .checkinout-text {
                font-size: 12px;
                color: #676767;
                line-height: 14px;
                margin-bottom: 10px;
              }

              &:last-child::after {
                display: none;
              }
            }
          }
          @media screen and (max-width: 450px) {
            .jso-booking-check-in-out-content {
              flex-direction: column;
              .jso-date-range-picker-text {
                &:first-child {
                  border-right: none;
                  border-bottom: 1px solid rgba(27, 37, 64, 0.2);
                }
              }  
            }
          }
        }
        &-header {
          width: 100% !important;
          display: flex;
          align-items: start;
          justify-content: space-between;
          &-title {
            min-width: 516px;
            font-family: Cormorant Garamond;
            color: #78572c;
            font-weight: 400;
            font-size: 46px;
            line-height: 43px;
            letter-spacing: -2.4px;
          }
          &-priceDiv {
            display: flex;
            gap: 20px;
            align-items: center;
            width: 290px;
            &-price {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              &-text1 {
                font-size: 20px;
                color: #1B2540;
                font-family: Montserrat;
                font-weight: 500;
                display: flex;
                gap: 5px;
                span {
                  color: #78572c;
                }
              }
              &-text2 {
                font-family: Montserrat;
                line-height: 14.4px;
                font-size: 12px;
                color: #676767;
                min-width: 160px;
                font-weight: 500;
              }
              &-text3 {
                font-family: Montserrat;
                font-size: 14px;
                color: #1B2540;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: 0.24px;
                min-width: 115px;
                text-align: center;
              }
            }
            &-flexCol{
              display: flex;
              flex-direction: column;
              gap: 16px;
              &-button {
                padding: 16px 24px;
                background-color: #1B2540;
                color: #ffffff;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                font-family: Montserrat;
                border: 0;
              }
            }
          }

        }
        &-body {
          width: 100%;
          margin-top: 30px;
          position: relative;
          &-image {
            object-fit: cover;
            width: 100%;
            height: 700px;
            object-position: 0 74%;
          }
          &-drag {
            text-align: center;
            position: absolute;
            bottom: 145px;
            left: 45%;
            width: 120px;
            height: 120px;
            border-radius: 100%;
            padding: 30px 21px 18px 21px;
            border: 1px solid #edebe7;
            background-color: #edebe780;
            opacity: 80%;
            color: #1B2540;
          }
        }
        &-footer {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-bottom: 40px;
          &-item {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 7px;
            &-icon {
              width: 20px;
              height: 20px;
            }
            &-text {
              font-family: Montserrat;
              font-size: 12px;
              font-weight: 600;
              color: #1B2540;
            }
          }
        }
      }
    }
    &-star {
      font-size: 38px;
    }
  }

  .oasis {
    width: 100%;
    background: #f7f4f0;
    padding: 100px 60px 50px 60px;

    &-slider {
      &-main {
        height: 700px;
        max-height: 700px;

        &-container {
          img {
            object-fit: cover;
            width: 100%;
            height: 680px;
            max-height: 680px;
          }
        }
      }

      &-second {
        &-container {
          max-height: 82px;
          height: 81px;
          max-width: 92px;
          min-width: 91px;

          img {
            max-height: 82px;
            max-width: 92px;
            min-height: 81px;
            min-width: 91px;
            object-fit: cover;
          }
        }
      }
    }

    &-titleBox {
      display: flex;
      justify-content: center;

      &-title {
        width: 855px;
        color: var(--V2-Brown---Dark, #78572c);
        text-align: center;
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #000;
        font-family: 'Cormorant Garamond';
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 90%;
        letter-spacing: -2.4px;
        text-transform: uppercase;
        padding-bottom: 32px;
      }
    }
  }

  .section {
    width: 100%;
    height: 900px;
    position: relative;
    display: flex;
    justify-content: center;

    &-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: 0 74%;
    }

    &-gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 3, 23, 0.4) 26.12%, rgba(0, 3, 23, 0) 84.32%);
    }

    &-inner {
      margin-top: 72px;
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        color: #fff;
        text-align: center;
        font: 400 64px/90% 'Cormorant Garamond', serif;
        text-transform: uppercase;
        margin-bottom: 24px;
      }

      p {
        color: #fff;
        text-align: center;
        font: 500 16px/120% Montserrat, sans-serif;
        text-transform: uppercase;
      }
    }
  }

  .section:nth-child(1) .section-gradient {
    background: linear-gradient(180deg, rgba(188, 190, 207, 0.3) 26.12%, rgba(207, 211, 236, 0) 84.32%);
  }

  .amenities {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 100px 60px 70px 60px;
    background: #f7f4f0;

    li {
      border: none;
    }

    &-title {
      font-family: 'Cormorant Garamond';
      font-size: 64px;
      font-style: normal;
      color: #1B2540;
      font-weight: 400;
      line-height: 90%;
      letter-spacing: -3.2px;
      text-transform: uppercase;
      margin-bottom: 60px;
    }

    &-list {
      display: flex;
      list-style-type: none;
      align-items: center;
      column-gap: 25px;
      flex-wrap: wrap;

      &-title {
        color: #78572c;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      &-item {
        width: fit-content;
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 10px;
        justify-items: center;
        border: none;

        &-text {
          text-transform: capitalize;
          color: #78572c;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.2px;
          margin: 0;
        }
        &-img{
          width: 24px;
          height: 24px;
          margin: 0;
          // fill: #78572C !important;
          color: #78572C !important;
        }

        &-icon {
          width: 24px;
          height: 24px;
          color: #78572C;

        }
      }
    }
  }

  .exclusives {
    background: #1B2540;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    width: 100%;

    h2 {
      width: 800px;
      color: #fff;
      text-align: center;
      font-family: 'Cormorant Garamond';
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 57.6px;
      letter-spacing: -3.2px;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    &-description {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.2px;
      letter-spacing: 0.32px;
      text-transform: uppercase;
      margin-bottom: 72px;
    }

    .photo-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 40px;
    }

    .photo-card {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-inner {
        align-items: center;
        display: flex;

        &.left {
          flex-direction: row;
        }

        &.right {
          flex-direction: row-reverse;
        }

        &-imgBox {
          width: 50%;

          &-img {
            object-fit: cover;
            width: 100%;
          }
        }

        &-textBox {
          width: 50%;
          padding: 0 60px;

          &-title {
            color: #FFFFFF;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 28.8px;
            letter-spacing: -0.84px;
          }

          &-text {
            max-width: 840px;
            color: #FFFFFF;
            font-family: Montserrat;
            font-size: 17px;
            font-style: normal;
            font-weight: 300;
            line-height: 20.4px;
            letter-spacing: -0.2px;
          }
        }
      }
    }
  }

  .extras {
    background: #1B2540;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 72px 0;

    &-textbox {
      width: 75%;

      h2 {
        color: #fff;
        text-align: center;
        font-family: Cormorant Garamond;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 90%;
        letter-spacing: -3.2px;
        text-transform: uppercase;
      }

      p {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.32px;
        text-transform: uppercase;
        margin-bottom: 24px;
      }
    }

    .card-wrapper {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 985px;
    }

    .card {
      min-height: 428px;
      width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: unset;
      border: none;
      text-align: center;
      overflow: hidden;

      &-icon {
        min-height: 300px;
        width: 100%;
      }

      p {
        width: 251px;
        font-family: 'Cormorant Garamond';
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 90%;
        letter-spacing: -1.08px;
      }
    }

    .card:last-child p {
      width: 210px;
    }
  }

  .footer {
    &-menu {
      display: flex;
      flex-direction: row;
      width: 70%;
      justify-content: space-around;
    }

    &-button-container {
      width: 25%;
      max-width: 315px;
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      padding: 45px 60px 70px 0px;
      background-color: #1B2540;
      justify-content: space-around;

      &-title {
        color: white;
        font-family: Cormorant Garamond;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 90%;
        letter-spacing: -1.08px;
      }
    }
    &-bottom {
      background-color: #1B2540;
      text-align: center;
      padding: 20px 0;
      &-button{
        background-color: #1B2540;
        padding: 10px 30px;
        color: #ffffff;
        border-color: #ffffff;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        font-family: Montserrat;
        color: #ffffff;
      }
    }
    &-copyrigh-container {
      background-color: #1B2540;
    }

    &-copyright {
      padding: 20px 60px;
      background-color: #1B2540;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0 !important;

      &-text {
        color: #637988;

        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.32px;
        text-transform: uppercase;
      }

      &-buttons {
        display: flex;
        flex-direction: row;
        gap: 15px;

        &-text {
          color: #fff;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: -0.24px;
          text-decoration-line: underline;
        }
      }
    }

    .title {
      color: white;
      font-family: Cormorant Garamond;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 90%;
      letter-spacing: -1.08px;
    }

    .list-title {
      color: #637988;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.14px;
      margin-bottom: 20px;
    }

    .list-item {
      color: #fff;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.2px;
    }

    .list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .margin20 {
      margin-bottom: 20px;
    }

    .button-label {
      color: #fff;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.14px;
      margin-bottom: 20px;
    }

    .button {
      width: 100%;
      display: flex;
      padding: 16px 4px;
      justify-content: center;
      align-items: center;
      color: #1B2540;
      text-align: center;

      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      align-self: stretch;
      border: none;
    }
  }

  .map {
    background: #f7f4f0;
    width: 100%;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-button {
      background-color: #1B2540;
      color: #efefef;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      padding: 12px 24px;
      margin-bottom: 15px;
      border: none
    }

    h2 {
      width: 700px;
      color: #78572c;
      text-align: center;
      font-family: 'Cormorant Garamond';
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 90%;
      letter-spacing: -3.2px;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    p {
      color: #78572c;
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: 0.32px;
      text-transform: uppercase;
      margin-bottom: 56px;
    }

    &-container {
      width: 95%;
      height: 720px;
      position: relative;
      overflow: hidden;
    }

    &-tooltip {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        color: #1B2540;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.2px;
        margin-bottom: 10px !important;
      }
    }
  }

  button.gm-ui-hover-effect {
    display: none !important;
  }

  div.gm-style-iw.gm-style-iw-c {
    padding: 16px 24px !important;
    width: 299px !important;
    background: #fff !important;
    border-radius: unset !important;
  }

  div.gm-style-iw-d {
    overflow: hidden !important;
  }

  div.gm-style .gm-style-iw-tc {
    top: -120px !important;
    transform: translate(-50%) rotateX(180deg) !important;
  }

  div.gm-style-iw-t {
    bottom: -95px !important;
  }
}

@media screen and (max-width: 1720px) {
  .fb {
    &-startTour {
      &-items {
        padding: 50px 100px 0 100px;
        gap: 30px;
        flex-direction: column;
        &-item {
          &-header {
            width: 100%;
            &-title {
              font-size: 38px;
              min-width: unset;
            }
            &-text3 {
              min-width: unset;
            }
            &-button {
              padding: 12px 20px;
              font-size: 11px;
              width: 100%;
            }
          }
        }
      }
    }
    .exclusives {
      .photo-card {
        &-inner {
          &-textBox {
            &-title {
              font-size: 22px;
            }
            &-text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .fb {
    &-menu {
      padding: 24px 20px;
    }

    &-welcome {
      justify-items: start;

      &-button {
        padding: 18px 20px;
      }

      &-carusel {
        &-textbox {
          top: 53%;
          left: 50%;
          padding: 0 10px;
          h1 {
            font-size: 48px;
            margin-bottom: 20px;
          }
        }

        &-item {
          &-background {
            object-position: 50% 15%;
          }
        }
      }
    }

    &-discover {
      &__room {
        width: 85%;
        object-fit: cover;
      }
    }

    &-startTour {
      &-title {
        font-size: 60px;
      }
      &-items {
        &-item {
          &-header {
            flex-direction: column;
            gap: 20px;
            &-title {
              width: 100%;
              font-size: 32px;
              text-align: center;
            }
            &-priceDiv {
              width: 100%;
              justify-content: space-between;
              &-price {
                &-text1 {
                  font-size: 18px;
                }
              }
            }
            &-button {
              padding: 12px 20px;
              font-size: 11px;
            }
          }
          &-body {
            &-drag {
              width: 100px;
              height: 100px;
              bottom: 30px;
              left: 42%;
              margin: 0 auto;
              padding: 18px 10px 10px 10px;
              font-size: 14px;
            }
          }
        }
      }
    }

    .oasis {
      padding: 60px 40px 60px 40px;

      &-titleBox {
        &-title {
          padding-bottom: 30px;
          width: 754px;
          color: var(--V2-Brown---Dark, #78572c);
          text-align: center;
          font-family: 'Cormorant Garamond';
          font-size: 48px;
          font-style: normal;
          font-weight: 400;
          line-height: 90%;
          letter-spacing: -2.4px;
          text-transform: uppercase;
        }
      }
    }

    .section {
      &-inner {
        width: 100%;

        h2 {
          max-width: 550px;
          min-width: 200px;
          width: 100%;
          padding: 0 15px;

          font-size: 48px;
        }

        p {
          max-width: 550px;
          min-width: 200px;
          width: 100%;
          padding: 0 15px;
          font-size: 14px;
        }
      }

      &-img {
        object-position: 40% 50%;
      }
    }

    .extras {
      .card-wrapper {
        padding-left: 40px;
        padding-right: 40px;
        display: block;
        width: 100%;
        max-height: 500px;
        white-space: nowrap;
        overflow-x: auto;
      }

      .card {
        display: inline-block;
        width: 300px;
        height: auto;

        p {
          width: 240px;
          margin-left: 35px;
          white-space: break-spaces;
        }
      }
    }

    .amenities {
      padding: 72px 40px;

      li {
        padding: 3px 0;
      }
    }

    .footer {
      &-menu {
        padding-left: 40px;
        width: 100%;
        justify-content: space-between;
      }

      &-wrapper {
        flex-direction: column;
      }

      &-button-container {
        width: 40%;
        margin-top: 50px;
        position: relative;
        top: 50%;
        left: 50%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .fb {
    &-welcome {
      &-buttonDiv {
        padding: 24px 0;
        &-button {
          width: 55%;
          //padding: 14px 20px;
          min-width: 200px;
        }
      }


      &-carusel {
        &-textbox {
          top: 53%;
          left: 50%;

          h1 {
            font-size: 48px;
            margin-bottom: 20px;
            letter-spacing: -2.4px;
          }

          p {
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.24px;
          }
        }

        &-item {
          &-background {
            object-position: 50% 15%;
          }
        }
      }
    }

    &-discover {
      padding-top: 72px;
      padding-bottom: 72px;

      &__inner {
        width: 85%;
        margin-bottom: 56px;

        p {
          font-size: 28px;
          letter-spacing: -0.84px;
        }
      }

      &__room {
        width: 95%;
        height: 530px;
        object-fit: cover;
        background-size: contain;
      }
    }

    &-startTour {
      &-title {
        font-size: 54px;
      }
      &-items {
        padding: 50px;
        &-item {
          &-header {
            flex-direction: column;
            gap: 20px;
            &-priceDiv {
              width: 100%;
            }
            &-title {
              width: 100%;
              font-size: 28px;
              text-align: center;
            }
          }
          &-body {
            &-drag {
              width: 90px;
              height: 90px;
              bottom: 30px;
              left: 38%;
              padding: 18px 10px 10px 10px;
              font-size: 14px;
            }
          }
        }
      }
    }

    .oasis {
      padding: 60px 12px 60px 12px;
      &-slider {
        &-main {
          height: 400px;
          max-height: 400px;

          &-container {
            img {
              object-fit: cover;
              width: 100%;
              height: auto;
              max-height: 680px;
            }
          }
        }
      }
      &-titleBox {
        &-title {
          width: 100%;
          padding-bottom: 24px;
          color: var(--V2-Brown---Dark, #78572c);
          text-align: center;
          font-family: 'Cormorant Garamond';
          font-size: 48px;
          font-style: normal;
          font-weight: 400;
          line-height: 90%;
          letter-spacing: -2.4px;
          text-transform: uppercase;
        }
      }
    }

    .section {
      &-inner {
        h2 {
          font-size: 48px;
          letter-spacing: -2.4px;
          margin-bottom: 20px;
        }

        p {
          font-size: 12px;
          line-height: 100%;
          /* 12px */
          letter-spacing: 0.24px;
        }
      }

      &-img {
        object-position: 55% 50%;
      }
    }

    .section:nth-child(4) .section-img {
      object-position: 48% 50%;
    }

    .section:nth-child(2) .section-img {
      object-position: 67% 50%;
    }

    .map {
      height: 900px;
      padding: 72px 0;

      &-container {
        width: 92%;
      }

      h2 {
        width: 90%;
        font-size: 48px;
        letter-spacing: -2.4px;
      }

      p {
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.24px;
      }
    }

    .extras {
      padding-top: 72px;

      &-textbox {
        width: 90%;

        h2 {
          font-size: 45px;
          letter-spacing: -2.4px;
          margin-bottom: 0;
        }

        p {
          font-family: 'Montserrat' !important;
          font-size: 12px !important;
          font-weight: 500 !important;
          line-height: 100% !important;
        }
      }

      .carousel-indicators {
        z-index: 20;
        padding-bottom: 5px;
        width: 100%;
        margin-bottom: -10px;
        margin-left: 0;
        margin-right: 0;

        button {
          height: 1px;
          width: 35px;
        }
      }
    }

    .amenities {
      padding: 72px 20px;

      h2 {
        font-size: 48px;
        letter-spacing: -2.4px;
      }

      &-list {
        padding: 0;

        &-title {
          font-size: 12px;
          letter-spacing: 0.24px;
        }

        &-item {
          &-text {
            font-family: Montserrat;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.14px;
          }
        }
      }

      li {
        padding: 3px 0;
        width: 46%;
      }
    }

    .exclusives {
      padding: 80px 0;

      h2 {
        width: unset;
        font-size: 48px;
        letter-spacing: -2.4px;
      }

      &-description {
        width: 200px;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.24px;
      }

      .photo-card {
        &-inner {
          &.left {
            flex-direction: column;
            align-items: flex-start;
          }

          &.right {
            flex-direction: column;
            align-items: flex-end;
          }

          &-imgBox {
            width: 70%;

            &-img {
              height: 350px;
            }
          }

          &-textBox {
            width: 70%;
            padding: 20px 24px 0 24px;

            &-title {
              font-size: 20px;
              font-weight: 400;
              letter-spacing: -0.4px;
            }

            &-text {
              font-size: 14px;
              font-weight: 400;
              letter-spacing: -0.14px;
            }
          }
        }
      }
    }

    .footer {
      &-menu {
        padding-left: 20px;
        width: 100%;
        justify-content: space-between;
      }

      &-wrapper {
        flex-direction: column;
        padding: 50px 20px 50px 0px;

        &-title {
          width: 100%;
          font-size: 32px;
          margin-bottom: 40px;

          br {
            display: none;
          }
        }

        .list-one {
          width: 50%;
        }

        .list-two {
          width: 50%;
        }

        .list-title {
          font-weight: 600;
          margin-bottom: 10px;
        }

        .list {
          li {
            margin-bottom: 10px;
          }

          &-item {
            font-size: 16px;
          }
        }

        .footer-menu {
          flex-wrap: wrap;
        }
      }

      &-button-container {
        width: calc(100% - 20px);
        margin-top: 50px;
        position: relative;
        top: 50%;
        left: 0;
        margin-left: 20px;
      }

      .footer-copyrigh-container {
        padding-bottom: 40px;
      }

      &-copyright {
        width: calc(100% - 40px);
        margin: 0 auto;
        flex-direction: column;
        justify-content: center;

        &-buttons {
          margin-bottom: 14px;
        }

        &-text {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }
}

.sec-1 {
  h2 {
    width: 700px;
  }

  p {
    width: 400px;
  }
}

.sec-2 {
  h2 {
    width: 650px;
  }

  p {
    width: 500px;
  }
}

.sec-3 {
  h2 {
    width: 700px;
  }

  p {
    width: 500px;
  }
}

.sec-4 {
  margin-top: 0 !important;
  padding-top: 72px;
  opacity: 0.8;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.34) 0%, rgba(0, 0, 0, 0) 95.24%);
  backdrop-filter: blur(13px) !important;
  padding-bottom: 100px;

  h2 {
    width: 640px;
  }

  p {
    width: 500px;
  }
}

.sec-5 {
  h2 {
    width: 700px;
  }

  p {
    width: 657px !important;
  }
}

.sec-6 {
  h2 {
    width: 700px;
  }

  p {
    width: 400px;
  }
}

.sec-7 {
  h2 {
    width: 600px;
  }

  p {
    width: 400px;
  }
}

@media screen and (max-width: 576px) {
  .sec-1 {
    h2 {
      width: 347px;
    }

    p {
      width: 292px;
    }
  }
}

@media screen and (max-width: 576px) {
  .sec-2 {
    h2 {
      width: 366px;
    }

    p {
      width: 222px;
    }
  }
}

@media screen and (max-width: 576px) {
  .sec-3 {
    h2 {
      width: 366px;
    }

    p {
      width: 224px;
    }
  }
}

@media screen and (max-width: 576px) {
  .sec-4 {
    h2 {
      width: 319px;
    }

    p {
      width: 241px;
    }
  }
}

@media screen and (max-width: 576px) {
  .sec-5 {
    h2 {
      width: 331px;
    }

    p {
      width: 370px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .sec-6 {
    h2 {
      width: 366px;
    }

    p {
      width: 366px;
    }
  }
}

@media screen and (max-width: 576px) {
  .sec-7 {
    h2 {
      width: 366px;
    }

    p {
      width: 336px;
    }
  }
}

.oasis-slider-second .slick-slide.slick-current {
  border: 3px solid #1B2540 !important;
}

.oasis-slider-second .slick-slide {
  max-height: 87px;
  height: 87px;
  margin: 0 10px;
}

.slick-next {
  width: 34px !important;
  height: 34px !important;
  top: 50% !important;
  left: 95% !important;
  z-index: 1;
}

.slick-prev {
  width: 34px !important;
  height: 34px !important;
  top: 50% !important;
  left: 2% !important;
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .slick-next {
    top: 53% !important;
    left: 1% !important;
    margin-top: 440px;
    left: 54% !important;
  }

  .slick-prev {
    top: 53% !important;
    position: absolute;
    margin-top: 440px;
    left: 46% !important;
  }
}

@media screen and (max-width: 576px) {
  .slick-next {
    width: 30px !important;
    height: 30px !important;
    margin-top: 440px;
    left: 57% !important;
  }

  .slick-prev {
    width: 30px !important;
    height: 30px !important;
    margin-top: 440px;
    left: 43% !important;
  }
}

.reserveButtonDisabled {
  opacity: 20%;
  color: #FFFFFF;
}
