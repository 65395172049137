@import '../../App.scss';

@media only screen and (min-width: 1643px) {
    .new-search-container.container {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    .new-search-container.container {
        padding: 0 20px;
    }
}

.select-price-sorting{
    background: white !important;
    border-radius: 0 !important;
    padding: 16px 36px !important;
    font-size: 14px;
    font-family: 'Montserrat' !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    line-height: 20px !important;

    option {
        text-transform: capitalize;
    }
}

.sort-arrows{
    color: $base-blue;
    position: relative;
    top: 42px;
    left: 15px;
}

.mk-design{
    .mk-search{
        &.jso-booking-form.jso-booking-form-colored.jso-booking-form-separator {
            .jso-booking-form-content{
                .jso-booking-form-title{
                    background: unset!important;
                    background-color: white!important;
                    border: unset;
                    border-radius: unset;
                    color:$base-blue!important;
                    border-bottom-color:$base-border-color!important;
                    padding: 16px;
                    font-size: 24px !important;
                    font-family: 'Cormorant Garamond' !important;
                    font-weight: 500 !important;
                    line-height: 24px;
                    letter-spacing: -0.48px;

                    @media only screen and (max-width: 768px) {
                        font-size: 20px !important;
                    }

                    svg{
                        @media only screen and (max-width: 768px) {
                            font-size: 20px !important;
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
                .jso-booking-check-in-out, .jso-booking-form-guests.jso-quantity-selector-closed{
                    background: white;
                    border: solid 1px $base-border-color!important;
                    border-radius: unset;
                }
                .jso-booking-check-in-out-content, .jso-booking-form-guests {
                    background: white !important;
                    border-radius: unset;
                    height: initial;
                    .jso-date-range-picker-text, .jso-quantity-selector-text{
                        color: #676767;
                        border-color: $base-border-color!important;
                        border-left: unset;
                        padding: 16px;
                        font-weight: 500;
                        font-size: 18px;
                        font-family: 'Montserrat';
                        line-height: 28.8px;
                        text-wrap: wrap;

                        @media only screen and (max-width: 768px) {
                            font-size: 16px;
                            line-height: 25.6px;
                        }
                    } 
                }
                .jso-quantity-selector-fields {
                    height: initial;
                }
                .jso-booking-form-search-btn{
                    background-color: $base-blue!important;
                    border-radius: unset;
                    height: 117px;
                    width: 117px;

                    @media only screen and (max-width: 768px) {
                        height: 52px;
                        width: 100%;
                    }
                }
            }
        }
    }

.jso-booking-check-in-out, .jso-booking-form-guests{
    flex: 1 ;
    border-radius: 0;
}

.jso-booking-form-title{
    font-family: 'Cormorant Garamond';
    text-transform: capitalize;
    font-size: 1rem;
    color:$base-blue
}

}
.page-title{
    font-weight: 500;
    font-family: 'Cormorant Garamond';
    text-transform: capitalize;
    font-size: 5rem;
    color: $base-blue !important;
    letter-spacing: -1.6px;
    line-height: 80px;

    @media only screen and (max-width: 767px) {
        text-align: left !important;
        font-size: 40px;
        letter-spacing: -0.8px;
        line-height: 52px;
    } 
}

.listings-available {
    font-size: 18px;
    font-family: 'Montserrat';
    color: #676767;
    font-weight: 500;
}

.search-results {
    display:   flex;
    flex-wrap: wrap;

    > div {
        padding-right: 1.5rem;

        @media only screen and (min-width: 1200px) {

            &:nth-child(3n+3) {
                padding-right: 0;
            }

        }

        @media only screen and (max-width: 1200px) and (min-width: 768px) {

            &:nth-child(2n+2) {
                padding-right: 0;
            }

        }

        @media only screen and (max-width: 767px) {

            padding-right: 0;

        }

    }

}

.search_form_wrapper {
    background:   white;
    border-radius: 0 !important;
    padding:       20px;

}

.tabsDate{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tabs{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 25.6px;

    @media only screen and (max-width: 768px) {
        line-height: 20.3px;
    }
}
.tab-title{
    color: #FFFFFF;
    padding: 12px 24px;
    font-size: 16px;
    font-family: 'Montserrat';
    cursor: pointer;
    background-color: #1B2540;

    @media only screen and (max-width: 768px) {
        font-size: 14px;
        letter-spacing: 1.12px;
    }
}
.tab-title-active{
    color: #1B2540;
    padding: 12px 24px;
    font-size: 16px;
    font-family: 'Montserrat';
    cursor: pointer;
    background-color: #FFFFFF;

    @media only screen and (max-width: 768px) {
        font-size: 14px;
        letter-spacing: 1.12px;
    }
}
.date-picker{
    width: 100%;
}

@media screen and (min-width: 1000px) {
    .search_form_wrapper.sticky {
        position: fixed;
        top:      20px;
        z-index:  999;
    }
}

.heading-bc {
    display: flex;
    flex-direction: column;

    h1 {
        width: 360px;
        text-align: center;
    }

    .breadcrumbs {
        width: 50%;
        padding-top: 3em;
        text-transform: uppercase;
        font-family: Montserrat, sans-serif;
        font-weight: 500;

        nav {
            ol.breadcrumb {
                margin-bottom: 0 ;
            }
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: "/";
            color: #9FB0BA;
            ;
        }
        
        .breadcrumb-item{
            a{
               color: $base-blue;
            }
        }

        .breadcrumb-item.active {
            color:  #9FB0BA;
        }
    }
    .breadcrumbs.breadcrumbs_search_page {
        justify-content: flex-start;
        
        @media only screen and (min-width: 767px) {
            padding-left: 25px;
        }
    }

}
@media screen and (max-width: 991px) {
    .heading-bc {
        flex-direction: column;
        align-items: center;
        text-align: center;

        h1 {
            width: 100%;
            text-align: center;
            margin-left: 0;
        }

        .breadcrumbs {
            width: 100%;
            padding: 0;
            align-items: center;
            text-align: center;
            display: flex;
            justify-content: center;
            margin: 0;
            ol {
                margin-bottom: 0px;
                margin-top: 36px;
            }
        }
    }
}

.contact-section {
    margin-top: 108px;
}
