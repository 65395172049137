@import '../../App.scss';

.checkout {
  margin: 0 120px;
  h2 {
    font-family: 'Cormorant Garamond';
    font-size: 36px;
    font-weight: 400;
  }

  h3 {
    color: white;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
  }

  @media only screen and (max-width: 768px) {
    margin: 0 40px;
    h2 {
      font-size: 28px;
    }
  }

  .col-section {
    @media only screen and (max-width: 768px) {
      padding: 0 !important;
    }
  }

  .location {
    color: var(--bs-primary);
    font-size: 24px;
  }

  .room_badge {
    background: var(--bs-primary);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 24px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 24px;
    padding: 8px 16px;
    width: fit-content;
  }

  .checkout_page_row_container {
    margin-bottom: 140px;
    margin-right: -35px;

    .hotel_info_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .margin_listing_data {
    margin-top: 48px;
  }

  @media only screen and (max-width: 768px) {
    .margin_listing_data {
      margin-top: 32px;
    }
  }

  .dates-container {
    margin-top: 48px;

    @media only screen and (max-width: 768px) {
      margin-top: 0;
    }

    .dates {
      display: flex;
      gap: 1px;
      width: 100%;
      .item {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 8px;
        padding: 28px 32px;
  
        @media only screen and (max-width: 768px) {
          padding: 24px 16px;
        }
      }
      div {
        background-color: white;
        padding: 28px 32px;
      }
      p {
        font-weight: 400;
        color: #000000;
        font-family: Montserrat;
        line-height: 28.8px;
      }
      .date {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
      }
      .time {
        font-size: 16px;
        font-weight: 400;
      }
    }
  
    small {
      color: #676767;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
    }
    @media only screen and (max-width: 768px) {
      small {
        font-size: 14px;
      }
    }
  }

  .checkout_page_register_block {
    background-color: white;
    background-image: url('https://vacations.makrealty.com/wp-content/themes/hotelbooking/img/checkout_woman.svg');
    background-repeat: no-repeat;
    background-size: contain;
    color: black;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    font-family: Montserrat;
    justify-content: space-between;
    align-items: center;
    line-height: 16px;
    height: 100%;
    padding: 24px 24px 24px 200px;

    .registerText {
      margin-left: 35px;
      font-size: 16px;
      font-weight: 400;
      line-height: 25.6px;
      color: #000
    }
    @media only screen and (max-width: 768px) {
      .registerText {
        font-size: 14px;
        line-height: 21px;
      }
    }

    @media only screen and (max-width: 1024px) {
      padding: 32px 32px 32px 180px;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 22px;
      padding-left: 160px;
    }

    @media only screen and (max-width: 425px) {
      background-image: unset;
      padding-left: 32px;
    }

    a {
      border: 1px solid $base-blue;
      color: $base-blue;
      border-radius: 0;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      white-space: nowrap;
      padding: 22px 24px;
      text-transform: uppercase;
    }

    p {
      margin: 10px 0 10px 0;
    }
  }

  .PhoneInput {
    border: 1px solid #9fb0ba !important;
    padding-left: 1em;
    color: $base-blue;

    input,
    input:focus,
    input:active,
    input:active:focus,
    input:active:active,
    input:active:hover,
    input:hover,
    input:focus,
    input:focus-visible {
      border: none !important;
      outline: none !important;
      color: black !important;
    }

    &.invalid {
      border: 1px solid #dc3545 !important;
    }
  }

  .phone-error {
    display: inline-block;
  }
  .select::after {
    color: #181a26 !important;
  }

  .checkout_page_right_block {
    background-color: white;
    display: block;
    overflow: auto;
    padding: 30px;

    @media only screen and (max-width: 768px) {
      padding: 36px 24px;
    }
  }

  input,
  textarea,
  select {
    width: 100%;
  }

  .room-image {
    border-radius: 24px;
    height: 340px;
    object-fit: cover;
  }

  .form-check {
    input[type='radio'] {
      height: 15px;
      width: 15px;
      border: 1px solid $base-blue;
      //background: white;
    }
  }
  .form-check-input {
    --bs-form-check-bg: white;
  }

  .form-check-input:checked[type='radio'] {
    border-color: white;
    --bs-form-check-bg-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%271%27 fill=%27%231B2540%27/%3e%3c/svg%3e');
  }
  .form-check-input:checked {
    background-color: $base-blue;
  }

  .form-check-input:focus,
  .form-control:focus {
    box-shadow: 0 0 0 0.1rem $base-blue !important;
  }

  @media only screen and (max-width: 768px) {
    .checkout_page_register_block {
      background-image: none;
      padding: 20px 16px;
      justify-content: space-between;
      .registerText {
        margin: 0;
      }
    }

    .checkout_page_right_block {
      margin-top: 40px;
    }
  }

  @media only screen and (min-width: 768px) {
    > .checkout_page_row_container > div:nth-child(1) .checkout_page_register_block {
      display: none;
    }
  }

  .difficultNavigateTextarea {
    color: #000000 !important;
  }
  .difficultNavigateTextarea::placeholder {
    color: #676767 !important;
  }
}

#checkout_page_success_section,
#checkout_page_thank_you_table {
  display: none;
}

#checkout_page_payment_section {
  ul {
    list-style: none;
    padding-left: 0;

    li {
      outline: unset !important;
    }
  }

  .checkout_page_payment_option {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    padding: 19px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 1px solid $base-blue;
    cursor: pointer;
    color: $base-blue;

    &.react-tabs__tab--selected {
      background-color: $base-blue;
      color: white;
    }

    .checkout_page_payment_option_radio {
      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23D9DBE9'/%3E%3C/svg%3E")
        0 0 / contain no-repeat;
      display: inline-block;
      height: 24px;
      margin-right: 8px;
      vertical-align: middle;
      width: 24px;
    }
  }
}

#coupon-area {
  flex-direction: column;
  * {
    font-family: Montserrat;
  }

  label {
    color: black;
  }

  input {
    border-radius: 0;
    height: 43px;
    background: transparent;
    border: 1px solid #9fb0ba;
    color: $base-blue !important;
    &::placeholder {
      color: #676767;
    }
  }

  button {
    background-color: $base-blue;
    border-radius: 0;
    border-color: $base-blue;
    color: white !important;
  }
}

.payemntError {
  font-size: 18px !important;
}

.star-rating {
  display: flex;
  gap: 12px;
}

.resort {
  font-family: 'Cormorant Garamond';
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-top: 10px;
}

.location-text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
}

#checkout_page_listing_details_section {
  margin-top: 64px;

  h6 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 28.8px;
    }
  }

  .icon-text {
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 25px;
    color: #1B2540;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

#checkout_page_listing_details_section.checkout_page_listing_details_section_mobile {
  margin-top: 16px;
}

.details-showcase {
  display: flex;
  gap: 16px;
  div {
    padding: 0 0 12px 0;
    flex: 1;
    border-bottom: 1px solid #9fb0ba33;
  }
  p {
    color: $base-blue;
  }
}

#checkout-form {
  input[type='text'],
  input[type='email'],
  select {
    border-radius: 0;
    border: 1px solid #9fb0ba;
    background: transparent;
    color: black !important;
    &::placeholder {
      color: #676767;
    }
  }

  label {
    color: black;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
  }
}
.guest_details_container.guest_details_container_with_no_padding {
  @media only screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.guest_details_container {
  .form-wrapper {
    background: white;
    padding: 32px;
    height: fit-content;
    h2 {
      font-family: Cormorant Garamond;
    }
    @media only screen and (max-width: 768px) {
      padding: 24px 16px;
    }
  }
}

.payemntError:empty {
  margin: 0;
}

.form-double-row {
  & > div {
    flex: 1;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    & > div:first-child {
      margin-bottom: 2rem;
    }
  }
}

.multiselect.empty button.dropdown-toggle {
  color: #676767 !important;
  font-family: Montserrat !important;
  font-weight: 400 !important;
  border: 1px solid #9fb0ba;
  border-radius: 0;
  text-align: left;
  &::after {
    all: unset;
  }
}

.multiselect button.dropdown-toggle {
  background: white !important;
  color: #676767 !important;
  border: 1px solid #9fb0ba !important;
  border-radius: 0 !important;
}

.multiselect .dropdown-menu {
  background-color: white !important;
}

.payment-info-header {
  font-family: 'Cormorant Garamond';
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 32px;
}

.breakdownAcardion {
  .accordion-item {
    .price-accordion-title {
      button {
        color: #1B2540;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
        padding: 0px 0px 20px 0px;
      }
    }
  }
}

.pricing_table_wrapper {
  margin-top: 15px;
  thead {
    td {
      color: $base-blue !important;
    }
  }
  tbody {
    td {
      color: #676767 !important;
    }
  }

  tfoot {
    tr td:last-child {
      color: black !important;
      text-align: right;
    }
  }
}

.checkout_page_payment_option-wrapper {
  display: flex;
  align-items: stretch;
}

.payment-info {
  margin-top: 32px;
  .policy {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;

    color: #676767;
    span {
      color: black;
    }
  }

  label.terms {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    color: black;
    a {
      text-decoration: underline;
      color: black;
    }
  }
}

#checkout_page_stripe_submit_button {
  border-radius: 0 !important;
  background-color: $base-blue !important;
  border-color: $base-blue !important;
}

.result-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-page {
  max-width: 832px;
  padding: 110px 64px 140px 64px;
  width: 100%;
}

.result-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.result-top-text {
  font-family: 'Cormorant Garamond';
  font-size: 56px;
  font-weight: 400;
  text-align: center;
  color: $base-blue;
  line-height: 56px;
}

.result-bot-text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: black;
}

.result-bot-text.result-bold-text {
  font-weight: 500;

  a {
    color: #1B2540;
  }
}

.reservation-details-table-header {
  color: black;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.try-again-btn {
  background-color: $base-blue;
  border: 0;
  width: 100%;
  padding: 22px 24px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: white;
  max-height: 70px;
}

.reservation-details {
  background-color: white;
  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  table {
    width: 100%;
  }
  tr {
    display: flex;
    border-bottom: 1px solid #edebe7;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }
  }
  td {
    flex: 1;
    font-family: Montserrat;
    font-size: 16px;
    color: #1a2028;
    word-break: break-word;

    ul {
      padding-left: 16px;
    }

    li::marker {
      font-size: 0.75rem;
    }
    &:first-child {
      font-weight: 400;
    }

    &:last-child {
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 768px) {
    gap: 32px;
    tr{
      flex-direction: column;
      gap: 5px;
    }
  }
}

.resort-showcase.resort-showcase-desktop {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.resort-showcase.resort-showcase-mobile {
  margin-top: 32px;

  @media only screen and (min-width: 768px) {
    display: none;
  }

  .details-showcase-col {
    border-bottom: none;
  }
}

.coupon-input {
  display: flex;
  gap: 24px;

  & > div:first-child {
    flex: 7;

    input {
      font-size: 16px;
    }
  }
  & > div:last-child {
    flex: 1;
    button {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) { 
    & > div:first-child {
      flex: 2;
    }
    & > div:last-child {
      flex: 1;
    }
  }
}

.icon-style{
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #1B2540;
  opacity: 0.4;
}

.not-validated {
  color: #f25a69;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: var(--bs-body-font-family, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
}